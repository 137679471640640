import React from "react"
// import PropTypes from "prop-types"
// import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"
import HandleOrphan from "/src/components/HandleOrphan/HandleOrphan"
import "./Services.scss"

import ServicesCard from "../Cards/ServicesCard"

import AI from "../../assets/img/Services/AI.jpg"
import BI from "../../assets/img/Services/BI.jpg"
import DS from "../../assets/img/Services/DS.jpg"
import DE from "../../assets/img/Services/DE.jpg"

const services = {
  subject: "SERVICES",
  title: "What We Do",
  description: [
    "We offer a suite of services aimed at solving your business needs. We will work alongside you to turn your business ideas into reality.",
  ],
}

const servicesData = [
  {
    title: "Artificial Intelligence",
    slug: "artificial-intelligence",
    description: [
      "Exceptional solutions that surpass expectations. We tailor-make our solutions to your needs using cutting-edge AI/ML technologies. So you can work smarter, not harder.",
    ],
    imgLink: AI,
  },
  {
    title: "Data Science",
    slug: "data-science-and-analytics",
    description: [
      "There is no problem, big or small, that we cannot resolve when it comes to data science. Our talented team dives into the complexity of analytics so you don’t have to. We discover insights you would otherwise not see.",
    ],
    imgLink: DS,
  },
  {
    title: "Business Intelligence",
    slug: "business-intelligence",
    description: [
      "Make critical business decisions you can be sure of. Our cutting-edge prediction technologies give you the power to see into the future.",
    ],
    imgLink: BI,
  },
  {
    title: "Data Engineering",
    slug: "data-engineering",
    description: [
      "Prepare and collect. Design scalable data pipelines that clean and transform your datasets. When your data is required, it will be in pristine shape.",
    ],
    imgLink: DE,
  },
]

export default function Services() {
  return (
    <section className="services-bg">
      <div className="services-area">
        <div className="wrapper services-zone align-items-start">
          <div className="article-remake__textBox services-zone__block1">
            <HandleOrphan>
              <h2 className=" fix-orphan article-remake__textBox__subject custom-color">
                {services.subject}
              </h2>
            </HandleOrphan>
            <HandleOrphan>
              <h2 className=" fix-orphan article-remake__textBox__title custom-color">
                {services.title}
              </h2>
            </HandleOrphan>
            <div className="article-remake__textBox__line"></div>
            <HandleOrphan>
              <p className=" fix-orphan article-remake__textBox__description custom-color">
                {services.description}
              </p>
            </HandleOrphan>
          </div>
          <div className="services-zone__block2">
            {servicesData.map((item, index) => {
              return (
                <div className="services-zone__block2__z" key={index}>
                  <ServicesCard
                    title={item.title}
                    description={item.description}
                    imgLink={item.imgLink}
                    slug={item.slug}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
