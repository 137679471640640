import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby-plugin-react-i18next"
import "./ServicesCard.scss"

ServicesCard.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string,
  description: PropTypes.string,
  imgLink: PropTypes.string,
}

ServicesCard.defaultProps = {
  isShowBottom: true,
}

export default function ServicesCard(props) {
  const { title, slug, imgLink, description } = props
  // const { t } = useTranslation()

  function handleClick() {
    window.location.href = `/services/${slug}`
  }
  return (
    <div
      className="card-wrapper"
      onClick={handleClick}
      onKeyDown={handleClick}
      aria-hidden="true"
    >
      <div
        className="services-card"
        style={{ backgroundImage: `url(${imgLink})` }}
      >
        <div className="services-card__titleBox">
          {/* <div className="services-card__titleBox__headLine"></div> */}
          <Link
            className="services-card__titleBox__text"
            to={`/services/${slug}`}
          >
            <h3>{title}</h3>
          </Link>
        </div>
        <p className="services-card__descriptionBox">{description}</p>
      </div>
    </div>
  )
}
